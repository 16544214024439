<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.state.voucherUsage.tableData"
      :fields="$store.getters['voucherUsage/tableFields']"
      :loading="$store.state.voucherUsage.loadingTable"
      :filter="$store.state.voucherUsage.tableFilter"
      :sort="$store.state.voucherUsage.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.voucherUsage.tableKeyword"
      :pagination="$store.state.voucherUsage.tablePagination"
      @changeFields="changeFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,

      selectedData: '',

      // Sort
      sortOptions: [
        {
          key: 'startedAt', direction: 'desc', label: 'Newest',
        },
        { key: 'startedAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: [
        'view',
      ],
    }
  },
  computed: {
    idVoucher() {
      return this.$route.params.id
    },
  },
  methods: {
    changeFields(val) {
      this.$store.commit('voucherUsage/SET_TABLE_FIELDS', val)
    },
    loadData() {
      return this.$store.dispatch('voucherUsage/getTableData', this.idVoucher)
    },
    filterData(val) {
      this.$store.commit('voucherUsage/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('voucherUsage/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('voucherUsage/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('voucherUsage/SET_TABLE_KEYWORD', val)
    },
  },
}
</script>
